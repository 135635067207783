import { IFeaturePanelFeatureItem } from '@msrceng/component';
import { useFeatureSugAdvisoryTab } from 'feature/advisory/useSugAdvisoryTab';
import { useFeatureNoActionCVE } from 'feature/noActionCVE/useFeatureNoActionCVE';

export const useSugFeatureList = (): IFeaturePanelFeatureItem[] => {
  const advisoryTab = useFeatureSugAdvisoryTab();
  const noActionCVE = useFeatureNoActionCVE();

  return [advisoryTab, noActionCVE];
};
